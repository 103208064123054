import React from 'react'
import "./Footer.css"

export default function Footer() {
    return (
        <footer className="footer">
          <p>&copy; 2024 Didier. Todos los derechos reservados.</p>
        </footer>
      );
    }
    